import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { AppBar, Toolbar, Box, Typography, Button, Menu, MenuItem, useMediaQuery, Avatar} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import DrawerComponent from './drawerComponent/drawerComponent';
import { logoutUserAction } from '../../../actions/userActions'
import styles from "../../../assets/styles/componentStyles.js";
import history from "../../../history.js";
import {setGlobalAlertAction} from "../../../actions/globalAlertActions";
import {siteURL, staticUrl} from "../../../config/api";
import { getInitials } from '../../../assets/commonFunctions';
import { Link } from 'react-router-dom';

const Header = props => {
    const theme = useTheme();
    const { user, subUser } = props
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);
    const [anchorE3, setAnchorE3] = useState(null);
    const [anchorE4, setAnchorE4] = useState(null);
    const isMatch = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLogout = () => {
        setAnchorEl(null);
        props.logOut();
    }
    const handleClick = e => {
        setAnchorE2(e.currentTarget);
    };
    const handleClose = (name) => {
        setAnchorE2(null);
        if(name !== ""){
            if(name === "support"){
                window.open(staticUrl + "/support/", "_blank");
            } else {
                history.push("/"+name);
            }
        }
    };
    const handleStart = e => {
        setAnchorEl(e.currentTarget);
    };
    const closeStart = (name) => {
        setAnchorEl(null);
        if(name !== ""){
            history.push("/"+name);
        }
    };
    const handleMyCrm = e => {
        setAnchorE3(e.currentTarget);
    };
    const closeMyCrm = (name) => {
        setAnchorE3(null);
        if(name !== ""){
            history.push("/"+name);
        }
    };
    const handleMyDesktop = e => {
        setAnchorE4(e.currentTarget);
    };
    const closeMyDesktop = (name) => {
        setAnchorE4(null);
        if(name !== ""){
            history.push("/"+name);
        }
    };
    const noPermission = () => {
        props.globalAlert({
            type: "Error",
            text: "You don't have permission. Please contact your admin.",
            open: true
        });
    }
    return (
        <Fragment>
            <Box>
                <AppBar elevation={0} color='transparent' position='static' sx={styles.header}>
                    <Toolbar>
                        <Typography style={{ flexGrow: 1 }}>
                            <Link to={"dashboard"} ><img src={siteURL+"/img/logo-s.svg"} alt="logo" style={styles.iconLogo} /></Link>
                        </Typography>
                        {isMatch ? (
                            <>
                                <DrawerComponent />
                            </>
                        ) : (
                            <>
                                <Box>
                                    <Button onClick={()=>{ return history.push("/dashboard")}}>Dashboard</Button>
                                    <Button aria-controls="start-mycrm" aria-haspopup="true" onClick={handleMyCrm}>My CRM</Button>
                                    <Menu id="start-mycrm" anchorEl={anchorE3} keepMounted open={Boolean(anchorE3)} onClose={()=>{closeMyCrm("")}}>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my crm") || subUser.memberId === 0 ? closeMyCrm("clientContact") : noPermission()}}>Client Contact</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my crm") || subUser.memberId === 0 ? closeMyCrm("mycalendar") : noPermission()}}>My Calendar</MenuItem>
                                        {/*<MenuItem onClick={()=>{ return props.menuLists.includes("my crm") || subUser.memberId === 0 ? closeMyCrm("mypipeline") : noPermission()}}>My Pipeline</MenuItem>*/}
                                        {/*<MenuItem onClick={()=>{ return props.menuLists.includes("my crm") || subUser.memberId === 0 ? closeMyCrm("mytasks") : noPermission()}}>My Tasks</MenuItem>*/}
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my crm") || subUser.memberId === 0 ? closeMyCrm("managesmsinbox") : noPermission()}}>SMS Inbox</MenuItem>
                                    </Menu>
                                    <Button aria-controls="start-mydesktop" aria-haspopup="true" onClick={handleMyDesktop}>My Desktop</Button>
                                    <Menu id="start-mydesktop" anchorEl={anchorE4} keepMounted open={Boolean(anchorE4)} onClose={()=>{closeMyDesktop("")}}>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("mypages") : noPermission()}}>My Pages Design</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("smstemplates") : noPermission()}}>SMS Templates</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("builditforme") : noPermission()}}>Build It For Me</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("myforms") : noPermission()}}>My Forms Design</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("mysurveytemplates") : noPermission()}}>Survey Design</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("myassessmenttemplates") : noPermission()}}>Assessment Design</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("my desktop") || subUser.memberId === 0 ? closeMyDesktop("mydrive") : noPermission()}}>My Drive</MenuItem>
                                    </Menu>
                                    <Button aria-controls="start-Campaign" aria-haspopup="true" onClick={handleStart}>
                                        My Campaigns
                                    </Button>
                                    <Menu id="start-Campaign" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={()=>{closeStart("")}}>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("email campaign") || subUser.memberId === 0 ? closeStart("manageemailcampaign") : noPermission()}}>Email Campaign</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("sms campaign") || subUser.memberId === 0 ? closeStart("managesmscampaign") : noPermission()}}>SMS Campaign</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("social media post") || subUser.memberId === 0 ? closeStart("managesocialmedia") : noPermission()}}>Social Media Post</MenuItem>
                                        <MenuItem onClick={()=>{ return closeStart("manageautomation")}}>Automation</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("surveys") || subUser.memberId === 0 ? closeStart("managesurvey") : noPermission()}}>Survey</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("sms polling") || subUser.memberId === 0 ? closeStart("managesmspolling") : noPermission()}}>SMS Polling</MenuItem>
                                        <MenuItem onClick={()=>{ return props.menuLists.includes("assessment") || subUser.memberId === 0 ? closeStart("manageassessment") : noPermission()}}>Assessment</MenuItem>
                                    </Menu>
                                </Box>
                                {(user && subUser) &&
                                    <Fragment>
                                        {
                                            subUser.memberId > 0 ?
                                                typeof subUser.imageUrl === "undefined" || subUser.imageUrl === null || subUser.imageUrl === "" ?
                                                    <div className='profile-image ml-2 cursor-pointer' onClick={handleClick}>{getInitials(subUser.firstName, subUser.lastName)}</div>
                                                :
                                                    <Avatar className="ml-2" alt="Avatar" src={subUser.imageUrl + `?v=${Math.floor(Math.random() * 100001)}`} onClick={handleClick} />
                                            :
                                                typeof user.imageUrl === "undefined" || user.imageUrl === null || user.imageUrl === "" ?
                                                    <div className='profile-image ml-2 cursor-pointer' onClick={handleClick}>{getInitials(user.firstName, user.lastName)}</div>
                                                :
                                                    <Avatar className="ml-2" alt="Avatar" src={user.imageUrl + `?v=${Math.floor(Math.random() * 100001)}`} onClick={handleClick} />
                                        }
                                        <Menu id="simple-menu" anchorEl={anchorE2} keepMounted open={Boolean(anchorE2)} onClose={()=> {handleClose("")}}>
                                            <MenuItem onClick={()=>{handleClose("memberinfo")}}>Edit Profile</MenuItem>
                                            {subUser.memberId === 0 && <MenuItem onClick={()=>{handleClose("manageusers")}}>Manage User</MenuItem>}
                                            <MenuItem onClick={()=>{ return props.menuLists.includes("domain and email verification") || subUser.memberId === 0 ? handleClose("domainemailverification") : noPermission()}}>Domain & Email Verifications</MenuItem>
                                            <MenuItem onClick={()=>{handleClose("contactus")}}>Contact Us</MenuItem>
                                            <MenuItem onClick={() => { handleClose("managesupportticket") }}>Support Ticket</MenuItem>
                                            <MenuItem onClick={() => { handleClose("support") }}>Knowledge Base</MenuItem>
                                            <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                                        </Menu>
                                    </Fragment>
                                }
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </Fragment>
    )
}
const mapStateToProps = ({ user,subUser,menuLists }) => ({ user,subUser,menuLists })
const mapDispatchToProps = dispatch => {
    return {
        globalAlert: (data) => {
            dispatch(setGlobalAlertAction(data))
        },
        logOut : () => {
            dispatch(logoutUserAction())
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Header)