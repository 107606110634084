import React from "react"
import { connect } from "react-redux"
import { setLoader } from "../../../actions/loaderActions"

const Loader = ({
    loader
}) => {
    return (
        <>
            {loader?.load ?
                <div className="loading-main d-flex flex-column align-items-center justify-content-center">
                    <div><i className="fad fa-spinner-third fa-spin fa-5x"></i></div>
                    {loader?.text && <div className="mt-3">{loader?.text}</div>}
                </div> :
                null}
        </>
    )
}

const mapStateToProps = state => {
    return {
        loader: state.loader
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoader: (data) => {
            dispatch(setLoader(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader)