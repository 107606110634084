import React, {useState} from 'react';
import {connect} from "react-redux";
import {List, ListItem, IconButton, ListItemText, Drawer, Collapse} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {logoutUserAction} from "../../../../actions/userActions";
import history from "../../../../history";
import {setGlobalAlertAction} from "../../../../actions/globalAlertActions";
import { staticUrl } from '../../../../config/api';

const DrawerComponent = (props) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [start, setStart] = React.useState(false);
    const [myProfile, setMyProfile] = React.useState(false);
    const [myCrm, setMyCrm] = React.useState(false);
    const [myDesktop, setMyDesktop] = React.useState(false);
    const handleStart = () => {
        setStart(!start);
    };
    const handleMyProfile = () => {
        setMyProfile(!myProfile);
    };
    const handleMyCrm = () => {
        setMyCrm(!myCrm);
    };
    const handleMyDesktop = () => {
        setMyDesktop(!myDesktop);
    };
    const handleSignOut = () => {
        props.logOut();
    }
    const noPermission = () => {
        props.globalAlert({
            type: "Error",
            text: "You don't have permission. Please contact your admin.",
            open: true
        });
    }
    const handleClickSupport = () => {
        window.open(staticUrl + "/support/", "_blank");
    }
    return (
        <>
            <Drawer anchor='right' onClose={() => setOpenDrawer(false)} open={openDrawer} onChange={() => setOpenDrawer(true)}>
                <List style={{width:"230px"}}>
                    <ListItem divider button onClick={()=>{ setOpenDrawer(false); return history.push("/dashboard"); }}>
                        <ListItemText> Dashboard</ListItemText>
                    </ListItem>
                    <ListItem divider button onClick={handleMyCrm}>
                        <ListItemText primary="My CRM"/>
                        {myCrm ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={myCrm} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding onClick={() => setOpenDrawer(false)}>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my crm") || props.subUser.memberId === 0 ? history.push("/clientContact") : noPermission()}}>
                                <ListItemText primary="Client Contact"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my crm") || props.subUser.memberId === 0 ? history.push("/mycalendar") : noPermission()}}>
                                <ListItemText primary="My Calendar"/>
                            </ListItem>
                            {/*<ListItem button onClick={()=>{ return props.menuLists.includes("my crm") || props.subUser.memberId === 0 ? history.push("/mypipeline") : noPermission()}}>*/}
                            {/*    <ListItemText primary="My Pipeline"/>*/}
                            {/*</ListItem>*/}
                            {/*<ListItem button onClick={()=>{ return props.menuLists.includes("my crm") || props.subUser.memberId === 0 ? history.push("/mytasks") : noPermission()}}>*/}
                            {/*    <ListItemText primary="My Tasks"/>*/}
                            {/*</ListItem>*/}
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my crm") || props.subUser.memberId === 0 ? history.push("/managesmsinbox") : noPermission()}}>
                                <ListItemText primary="SMS Inbox"/>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem divider button onClick={handleMyDesktop}>
                        <ListItemText primary="My Desktop"/>
                        {myDesktop ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={myDesktop} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding onClick={() => setOpenDrawer(false)}>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/mypages") : noPermission()}}>
                                <ListItemText primary="My Pages Design"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/smstemplates") : noPermission()}}>
                                <ListItemText primary="SMS Templates"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/builditforme") : noPermission()}}>
                                <ListItemText primary="Build It For Me"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/myforms") : noPermission()}}>
                                <ListItemText primary="My Forms Design"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/mysurveytemplates") : noPermission()}}>
                                <ListItemText primary="Survey Design"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/myassessmenttemplates") : noPermission()}}>
                                <ListItemText primary="Assessment Design"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("my desktop") || props.subUser.memberId === 0 ? history.push("/mydrive") : noPermission()}}>
                                <ListItemText primary="My Drive"/>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem divider button onClick={handleStart}>
                        <ListItemText primary="My Campaigns"/>
                        {start ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={start} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding onClick={() => setOpenDrawer(false)}>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("email campaign") || props.subUser.memberId === 0 ? history.push("/manageemailcampaign") : noPermission()}}>
                                <ListItemText primary="Email Campaign"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("sms campaign") || props.subUser.memberId === 0 ? history.push("/managesmscampaign") : noPermission()}}>
                                <ListItemText primary="SMS Campaign"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("social media post") || props.subUser.memberId === 0 ? history.push("/managesocialmedia") : noPermission()}}>
                                <ListItemText primary="Social Media Post"/>
                            </ListItem>
                            {/* <ListItem button onClick={()=>{ return handleClickAutomation()}}>
                                <ListItemText primary="Automation"/>
                            </ListItem> */}
                            <ListItem button onClick={()=>{ return history.push("/manageautomation")}}>
                                <ListItemText primary="Automation"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("surveys") || props.subUser.memberId === 0 ? history.push("/managesurvey") : noPermission()}}>
                                <ListItemText primary="Survey"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("sms polling") || props.subUser.memberId === 0 ? history.push("/managesmspolling") : noPermission()}}>
                                <ListItemText primary="SMS Polling"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return props.menuLists.includes("assessment") || props.subUser.memberId === 0 ? history.push("/manageassessment") : noPermission()}}>
                                <ListItemText primary="Assessment"/>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem divider button onClick={handleMyProfile}>
                        <ListItemText primary="My Profile"/>
                        {myProfile ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={myProfile} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding onClick={() => setOpenDrawer(false)}>
                            <ListItem button onClick={()=>{ return history.push("/memberinfo"); }}>
                                <ListItemText primary="Edit Profile"/>
                            </ListItem>
                            { props.subUser.memberId === 0 &&
                                <ListItem button onClick={()=>{ return history.push("/manageusers"); }}>
                                    <ListItemText primary="Manage User"/>
                                </ListItem>
                            }
                            <ListItem button onClick={()=>{ return props.menuLists.includes("domain and email verification") || props.subUser.memberId === 0 ? history.push("/domainemailverification") : noPermission()}}>
                                <ListItemText primary="Domain & Email Verifications"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return history.push("/contactus"); }}>
                                <ListItemText primary="Contact Us"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ return history.push("/managesupportticket") }}>
                                <ListItemText primary="Support Ticket"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ handleClickSupport() }}>
                                <ListItemText primary="Knowledge Base"/>
                            </ListItem>
                            <ListItem button onClick={()=>{ handleSignOut() }}>
                                <ListItemText primary="Sign Out"/>
                            </ListItem>
                        </List>
                    </Collapse>
                </List>
            </Drawer>
            <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon/>
            </IconButton>
        </>
    );
};
const mapStateToProps = ({ user,subUser,menuLists }) => ({ user,subUser,menuLists })
const mapDispatchToProps = dispatch => {
    return {
        globalAlert: (data) => {
            dispatch(setGlobalAlertAction(data))
        },
        logOut : () => {
            dispatch(logoutUserAction())
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(DrawerComponent)